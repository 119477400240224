/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import axios, { AxiosError, AxiosResponse } from "axios";
import { FileKey, FileUpload } from "../typings/api/file-upload";
import APIService from "./api.service";
import authHeader from "./auth-header";
import { File as FileType } from "../files/domain/file";

// const debug        = require("debug")("Neogen:FlowchartService");
export const FILE_API_URL =
    process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

// TODO: This needs to only allow access to files you should have access to

export interface PaginatedFilesProps {
    total: number;
    hasNextPage: boolean;
    pageItems: FileType[];
}

type FilterProps = {
    offset?: number;
    limit?: number;
    where?: any;
};

class FileService extends APIService<FileUpload> {
    constructor() {
        super("file-uploads");
    }

    getMyFiles(): Promise<void | AxiosResponse<PaginatedFilesProps>> {
        // This needs to check your roles
        // If you have accountant it should include files from anyone
        return this.getURL("/file-uploads");
        // return super.getAll();
    }

    async getFiles(filters: FilterProps, includeArchived?: boolean) {
        const response: any = await this.getPaginatedFiltered(filters, includeArchived ? "&showHidden=true" : "");
        return response.data as PaginatedFilesProps;
    }

    async getURL(URL: string): Promise<void | AxiosResponse<any, any>> {
        axios.get(FILE_API_URL + URL, { headers: authHeader() });
        try {
            return axios.get(FILE_API_URL + URL, { headers: authHeader() }).catch((error) => {
                console.warn(error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    console.error("Logout3");
                    // localStorage.removeItem("user");
                    // window.location.reload();
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message, FILE_API_URL);
                }
                console.log(error.config);
                console.log(error);
                console.warn(error.response);
                if (error.response?.status === 401 || error.response?.status === 403) {
                    console.error("Logout4");
                    // alert(this.endpoint);
                    localStorage.removeItem("user");
                    window.location.href = `/login/7?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
            });
        } catch (e) {
            const error = <AxiosError>(<any>e);
            console.error(error);

            if (error.response) {
                // if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.warn(error.response.status);
                if (error.response.status === 401 || error.response.status === 403) {
                    console.error("Logout5");
                    localStorage.removeItem("user");
                    window.location.href = `/login/1?redirectUri=${encodeURIComponent(window.location.pathname)}`;
                    throw new Error("Unauthorized");
                    // window.location.reload();
                }
                // }
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(error.request);
                // localStorage.removeItem("user");
                // window.location.reload();
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }
            console.log(error.config);
            console.log(error);
            // }
        }
    }

    // uploadFile(file: File): Promise<AxiosResponse<FileUpload>> {
    async uploadFile(
        file: File,
        userId: string,
        key?: FileKey,
        docType?: string,
        companyId?: number,
        processflowProgressId?: number,
    ): Promise<void | AxiosResponse<FileUpload>> {
        const formData = new FormData();

        // Update the formData object
        // if (selectedFile) {
        // console.log({ selectedFile, name: selectedFile.name, type: selectedFile.type, size: selectedFile.size })
        formData.append("myFile", file, file.name);
        formData.append("documentType", docType ? docType : "unknown");
        formData.append("companyId", companyId ? companyId.toString() : "");
        formData.append("processflowProgressId", processflowProgressId ? processflowProgressId.toString() : "");
        // }
        const res = await axios.post(FILE_API_URL + "/files", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                ...authHeader(),
            },
        });

        const newFile: FileUpload = {
            // id: res.data.id,
            filename: res.data[0].filename,
            encoding: res.data[0].encoding,
            mimetype: res.data[0].mimetype,
            size: res.data[0].size,

            dateUploaded: new Date(),
            originalFilename: res.data[0].originalname,
            // filename: res.data.filename,
            uploaded_by: userId,
            notes: "",
            lastUpdated: new Date(),
            documentTypeId: res.data[0].documentTypeId,
            key,
            isArchived: false,
            companyId: res.data[0].companyId,
            processflowProgressId: res.data[0].processflowProgressId,
            // hasMultiple: false,
        };
        const newRes = await this.create(newFile);

        if (newRes) {
            return newRes;
        }
    }

    clean(obj: any) {
        for (const propName in obj) {
            if (!Object.prototype.hasOwnProperty.call(obj, propName)) {
                continue;
            }
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    async create(fields: any, throwError = false): Promise<void | AxiosResponse<FileUpload>> {
        delete fields.id;
        // TODO: Not happy with this - Loopback 4 doesn't understand nullable things
        this.clean(fields);
        // console.log(this.constructor.name + " Creating with %O", fields);
        return axios.post(FILE_API_URL + this.endpoint, fields, { headers: authHeader() }).catch((e) => {
            console.error(e);
            console.log(e.response);
            if (throwError) {
                throw e;
            }
        });
    }

    createZip(filenames: string[]): Promise<any | AxiosResponse<any>> {
        return this.postURL("/file-uploads/zip", filenames);
    }

    async postURL(URL: string, data: any) {
        // console.error(data);
        return axios.post(FILE_API_URL + URL, data, { headers: authHeader() });
    }

    async deleteByID(id?: number): Promise<any> {
        if (!id) {
            throw new Error("You did not provide an id" + id);
        }
        const fullUrl = FILE_API_URL + this.endpoint + "/" + id;
        const header = authHeader();
        // console.log(fullUrl, header);
        return axios
            .delete(fullUrl, { headers: header })
            .catch((e) => {
                console.log("Delete Error: ", e.response);
                console.log("Delete Error: ", e);
            })
            .then((response) => {
                console.log("Delete Response: ", response);
            });
    }

    /**
     * Gets a single instance of a child by ID
     *
     * @param {Number} id - the ID of the child to return
     */
    async getOne(id: number | string): Promise<void | AxiosResponse<FileUpload>> {
        try {
            // console.log("Getting One:", API_URL + this.endpoint + "/" + id);
            const response = await axios.get(FILE_API_URL + this.endpoint + "/" + id, { headers: authHeader() });
            // console.log(response);
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Downloads a file with the given filename and original filename.
     * @param filename - The name of the file to download.
     * @param originalFilename - The original name of the file.
     * @throws {Error} - If the file cannot be downloaded.
     */
    async downloadFile(filename: string, originalFilename: string) {
        try {
            const response = await axios({
                url: FILE_API_URL + "/files/" + filename, //your url
                method: "GET",
                responseType: "blob", // important
                headers: {
                    ...authHeader(),
                },
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", originalFilename); //or any other extension
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

export default new FileService();
