import { useState, useMemo, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileService from "../../../services/file.service";
import fileUploadService from "../../../services/file.service";
import PageDescription from "../../../layout/page-description";
// import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import AddFile from "./modals/add-file";
import { useInfiniteQuery } from "@tanstack/react-query";
import { File } from "../../../files/domain/file";
import { usePaginatedResultItems } from "../../../hooks/usePaginatedResults";
import { PaginatedView } from "../../../layout/PaginatedView";
import usersService from "../../../services/users.service";
import ModalDialog from "../../../layout/modal-dialog";
import ButtonNeoGen from "../../../layout/button-neogen";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { Select } from "../../../layout/form/select-input";
import withReactContent from "sweetalert2-react-content";
import SwalNeogenFire from "../../../layout/swal-neogen";
import Toggle from "../../../layout/toggle";
import authService from "../../../services/auth.service";

export default function Files({ itemsPerPage = 20 }: { itemsPerPage?: number }) {
    const [showAddFile, setShowAddFile] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const queryCache = useQueryClient();
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [hideArchived, setHideArchived] = useState(true);
    const [canSeeAllFiles, setCanSeeAllFiles] = useState(false);

    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        authService.canIAccess("SEE_ALL_FILES").then((canSeeAll) => {
            if (canSeeAll) {
                setCanSeeAllFiles(true);
            }
        });
    }, []);

    const users = useMemo(
        () =>
            (usersQuery.data || []).map((user) => ({
                value: user.id as string,
                label: `${user.firstName || "Unknown"} ${user.lastName || ""} ${user.email ? `(${user.email})` : ""}`,
            })),
        [usersQuery.data],
    );

    const authToken = getAuthTokenNoThrow() || "no-auth";
    const companiesQuery = useCompanies({
        authToken,
    });

    const companies = useMemo(
        () => (companiesQuery.data || []).map((c) => ({ value: c.id, label: c.name || "" })),
        [companiesQuery.data],
    );

    function downloadZip() {
        SwalNeogenFire({
            title: "Creating Zip File",
            // html: (
            //     <>
            //         Please wait...
            //         <Loader2 />
            //     </>
            // ),
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false,
            didOpen: async () => {
                console.log({ files: paginatedFiles });
                const filenames = paginatedFiles?.map((file) => file.filename);
                if (filenames) {
                    fileService.createZip(filenames).then(async (r) => {
                        if (r) {
                            console.error(r.data.filename);
                            let filesize = 0;
                            while (!filesize) {
                                console.log("Checking");
                                const file = await fileService.getOne(r.data.id);
                                if (file) {
                                    filesize = file.data.size;
                                }
                                await new Promise((r) => setTimeout(r, 1000));
                            }
                            fileService.downloadFile(r.data.filename, r.data.originalFilename);
                            console.log("Downloaded");
                            Swal.close();
                            // return true;
                        }
                    });
                }

                console.log("Download All");

                return false;
            },
        })
            .then(() => {
                SwalNeogenFire({
                    title: "Zip Created - downloading",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                });
                // }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    type QueryKey = ["file-uploads"];

    const fetchFiles = async ({
        pageParam,
        queryKey,
    }: {
        pageParam?: { hasNextPage: boolean; startAt?: number };
        queryKey: QueryKey;
    }) => {
        const [_query] = queryKey;
        const { hasNextPage, startAt = 0 } = pageParam ?? { hasNextPage: true, startAt: 0 };
        try {
            if (!hasNextPage) {
                return { pages: [], total: 0, hasNextPage: false };
            }

            const response = await fileUploadService.getFiles(
                {
                    offset: startAt,
                    limit: itemsPerPage,
                    where: {
                        uploaded_by: selectedUser || undefined,
                        companyId: selectedCompany || undefined,
                    },
                },
                !hideArchived,
            );

            if (!response) {
                return { pages: [], total: 0, hasNextPage: false };
            }
            const { total, pageItems } = response;
            return {
                pages: pageItems || [],
                hasNextPage,
                startAt: startAt + (pageItems.length || 0),
                total,
            };
        } catch (error) {
            console.error("Could not fetch files");
            throw error;
        }
    };

    const { data, isFetchingNextPage, fetchNextPage, isLoading, refetch } = useInfiniteQuery(
        // @ts-ignore
        ["file-uploads", selectedUser, selectedCompany],
        fetchFiles,
        {
            refetchOnMount: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                const hasNextPage = lastPage?.hasNextPage || null;
                return { hasNextPage, startAt: lastPage?.startAt };
            },
        },
    );

    const paginatedFiles = usePaginatedResultItems<{ pages: File[]; hasNextPage: boolean }, File>(
        data,
        (response) => response.pages,
    );

    return (
        <>
            <PageDescription
                title="Files"
                description="Files are used to upload files to the system."
                buttons={[
                    // {
                    //     label: "Upload File",
                    //     icon: "fas fa-upload",
                    //     onClick: () => {
                    //         setShowAddFile(true);
                    //     },
                    // },
                    {
                        label: "Filters",
                        icon: "fas fa-filter",
                        onClick: () => {
                            setShowFiltersModal(true);
                        },
                    },
                    {
                        label: "Uploaded by: " + usersQuery.data?.find((u) => u.id === selectedUser)?.name,
                        icon: "fas fa-times",
                        type: "danger",
                        disabled: !selectedUser,
                        onClick: () => {
                            setSelectedUser(null);
                            refetch();
                        },
                    },
                    {
                        label: "Company: " + companiesQuery.data?.find((c) => c.id === selectedCompany)?.name,
                        icon: "fas fa-times",
                        type: "danger",
                        disabled: !selectedCompany,
                        onClick: () => {
                            setSelectedCompany(null);
                            refetch();
                        },
                    },
                    {
                        label: downloading ? "Downloading" : "Download All as Zip",
                        type: "secondary",
                        onClick: () => {
                            downloadZip();
                        },
                        icon: "fas fa-download",
                        disabled: downloading || (!selectedUser && !selectedCompany),
                    },
                ]}
            />
            <AddFile show={showAddFile} close={() => setShowAddFile(false)} />
            {isLoading ? (
                <Loader2 />
            ) : (
                <div className="">
                    <div className="">
                        {paginatedFiles && (
                            <PaginatedView
                                onRequestToLoadMore={fetchNextPage}
                                isLoading={isFetchingNextPage}
                                currentCount={paginatedFiles.length}
                                // pages[0] because all pages have the same `total`
                                totalCount={data?.pages[0]?.total || 0}
                            >
                                <TableNeogen
                                    entries={(paginatedFiles ?? []).map((file) => ({
                                        id: file.id,
                                        name: file.originalFilename || "-",
                                        type: file.key?.entryField || "-",
                                        // isArchived: file.isArchived,
                                        uploadedBy: file.uploaded_by || "-",
                                        company: companies.find((c) => c.value === file.companyId)?.label || "-",
                                        dateUploaded: file.dateUploaded || "-",
                                    }))}
                                    headers={["File Name", "Type", "Uploaded by", "Company", "Date Uploaded"]}
                                    formatters={[
                                        {
                                            field: "uploadedBy",
                                            type: "User",
                                        },
                                        {
                                            field: "dateUploaded",
                                            type: "DateTime",
                                        },
                                    ]}
                                    ignoreFields={[
                                        "key",
                                        "isArchived",
                                        "encoding",
                                        "documentTypeId",
                                        "notes",
                                        "filename",
                                        "mimetype",
                                    ]}
                                    actionsAreDropDown={true}
                                    actions={[
                                        {
                                            label: "Download",
                                            icon: "fas fa-download",
                                            onClick: (entry) => {
                                                const file = paginatedFiles?.find((file) => file.id === entry);
                                                if (file) {
                                                    fileService
                                                        .downloadFile(file.filename, file.originalFilename)
                                                        .catch((error) => {
                                                            console.error(error);
                                                            SwalNeogenFire({
                                                                title: "Error",
                                                                text:
                                                                    "There was an error downloading the file: " +
                                                                    error.response.statusText,
                                                                icon: "error",
                                                                showConfirmButton: false,
                                                                timer: 3500,
                                                            });
                                                        });
                                                } else {
                                                    // alert("Not found");
                                                    console.error({ entry, paginatedFiles });
                                                }
                                            },
                                        },
                                        {
                                            label: "Delete",
                                            icon: "fas fa-trash",
                                            className: "bg-red-500 text-white",
                                            onClick: (entry) => {
                                                console.error(entry);

                                                Swal.fire({
                                                    title: "Are you sure?",
                                                    text: "You won't be able to revert this!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                }).then((result) => {
                                                    if (result.value) {
                                                        fileUploadService.deleteByID(entry).then(() => {
                                                            queryCache.invalidateQueries(["files"]);
                                                            Swal.fire(
                                                                "Deleted!",
                                                                "Your file has been deleted.",
                                                                "success",
                                                            );
                                                        });
                                                    }
                                                });
                                            },
                                        },
                                    ]}
                                />
                            </PaginatedView>
                        )}
                    </div>
                </div>
            )}
            {showFiltersModal && (
                <ModalDialog
                    show={true}
                    close={() => setShowFiltersModal(false)}
                    title="Filters"
                    showCancel={false}
                    showOk={true}
                    okText="Ok"
                    okAction={() => {
                        setShowFiltersModal(false);
                        refetch();
                    }}
                >
                    <Select
                        value={selectedUser || ""}
                        options={users}
                        label="Uploaded by"
                        onChange={(value) => {
                            setSelectedUser(value as string);
                            // refetch();
                        }}
                    />
                    <br />
                    <Select
                        label="Company"
                        options={companies}
                        value={selectedCompany}
                        onChange={(value) => {
                            setSelectedCompany(value as number);
                            // refetch();
                        }}
                    />
                    <br />
                    {canSeeAllFiles && (
                        <>
                            <Toggle
                                options={["Show Archived", "Hide Archived"]}
                                selectedIndex={hideArchived ? 1 : 0}
                                setSelectedIndex={(value) => {
                                    console.log(value);
                                    setHideArchived(value === 1);
                                    // refetch();
                                }}
                            />
                            <br />
                        </>
                    )}
                </ModalDialog>
            )}
        </>
    );
}
